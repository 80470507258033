/* eslint-disable max-len */
import React from 'react';
import classnames from 'classnames';

import Props from './props';

const namespace = 'ui-homes-icon ui-homes-icon--full';
const ICON_ID = 'full_icon';

const IconFull = ({ className = null, accessibilityText }) => (
  <svg
    className={classnames(namespace, className)}
    aria-label={accessibilityText}
    role="img"
    width="41"
    height="13"
    viewBox="0 0 41 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconFull.propTypes = Props.propTypes;

const Icon = React.memo(IconFull);
Icon.ICON_ID = ICON_ID;

export default Icon;
